import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style/style.css'
const Sidebar = ({isVisible,toggleSidebar}) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [ShowDropDown, setShowDropDown] = useState(true)
    const [ShowDropDown1, setShowDropDown1] = useState(true)
    const [ShowDropDown2, setShowDropDown2] = useState(true)

    const SideMenu = [
        {
         name:'Board',
         icon:'/assets/icons/board.png',
         href:'/board'   
        },
        {
         name:'Medium',
         icon:'/assets/icons/medium.png',
         href:'/medium'
        },
        {
         name:'School' ,
         icon:'/assets/icons/class.png',
         href:'/schools'
        },
        {
         name:'Class',
         icon:'/assets/icons/class.png',
         href:'/class'
        },
        {
         name:'Sections',
         icon:'/assets/icons/class.png',
         href:'/sections'
        },
        {
         name:'Time Table',
         icon:'/assets/icons/class.png',
         href:'/time-table'
        },

    ]
    const subjectmenu = [
        {
         name:'Subject',
         icon:'/assets/icons/subject.png',
         href:'/subject'
        },
        {
         name:'Lesson',
         icon:'/assets/icons/lesson.png',
         href:'/lessons'
        },
        {
         name:'Material',
         icon:'/assets/icons/lesson.png' ,
         href:'/materials'
        }
    ]
    const ExamMenu = [
      {
        name:'Questions',
        icon:'/assets/icons/questions.png',
        href:'/questions'
       },
      {
        name:'Academy',
        icon:'/assets/icons/academy.png',
        href:'/academy'
       },
       {
        name:'Question Assign',
        icon:'/assets/icons/question_assign.png',
        href:'/qa_assign'
       },
       {
        name:'Score',
        icon:'/assets/icons/score.png',
        href:'/score'
       }

    ]

  return (
    <div className="sidebar-wrapper" style={{left:isVisible && '0px'}} data-simplebar="true">
      <div className="sidebar-header">
        <div>
          <img
            src="/assets/assets/images/logo-icon.png"
            className="logo-icon"
            alt="logo icon"
          />
        </div>
        <div>
          <h4 className="logo-text">Rocker</h4>
        </div>
        <div className="toggle-icon ms-auto">
          <i className="bx bx-arrow-back d-block d-lg-none" onClick={toggleSidebar} />
        </div>
      </div>
      {/*navigation*/}

      <ul className="metismenu" id="menu">
        <li>
          <a
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              navigate('/dashboard');
            }}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-category-alt" /> */}
              <img src='/assets/icons/dashboard.png' width={'19px'} height={'20px'}/>
            </div>
            <div className="menu-title text-md" style={{color:location.pathname == '/dashboard' && '#008cff'}}>Dashboard</div>
          </a>
        </li>
        <li>
          <a
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              navigate('/users');
            }}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-category-alt" /> */}
              <img src='/assets/icons/user.png' width={'19px'} height={'20px'}/>
            </div>
            <div className="menu-title text-md" style={{color:location.pathname == '/users' && '#008cff'}}>Users</div>
          </a>
        </li>
        <li>
          <a
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              navigate('/students');
            }}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-category-alt" /> */}
              <img src='/assets/icons/users.png' width={'19px'} height={'20px'}/>
            </div>
            <div className="menu-title text-md" style={{color:location.pathname == '/students' && '#008cff'}}>Students</div>
          </a>
        </li>
        <li>
          <a href="javascript:;" className="has-arrow" aria-expanded={`${ShowDropDown ? 'true' : 'false'}`}
            onClick={() => setShowDropDown(!ShowDropDown)}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-user-plus" /> */}
              <img src='/assets/icons/board_management.png' width={'19px'} height={'20px'}/>

            </div>
            <div className="menu-title text-md">Board Management</div>
          </a>
          <ul className={`${ShowDropDown ? 'd-block' : 'd-none'}`}>
            {SideMenu.map((item, index) => (
              <li key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default behavior
                    navigate(item.href); // Navigate to the specified link
                  }}
                >
                  <div className="">
                    {/* <i className={`bx ${item.icon}`} /> */}
                    <img src={item.icon} width={'18px'} height={'19px'}/>
                  </div>
                  <div className="menu-title text-md" style={{color:location.pathname == item.href && '#008cff'}}>{item.name}</div>
                </a>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <a href="javascript:;" className="has-arrow" aria-expanded={`${ShowDropDown1 ? 'true' : 'false'}`}
            onClick={() => setShowDropDown1(!ShowDropDown1)}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-user-plus" /> */}
              <img src='/assets/icons/subjects_and_lessons.png' width={'19px'} height={'20px'}/>

            </div>
            <div className="menu-title text-md">Subjects & Lessons</div>
          </a>
          <ul className={`${ShowDropDown1 ? 'd-block' : 'd-none'}`}>
            {subjectmenu.map((item, index) => (
              <li key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default behavior
                    navigate(item.href); // Navigate to the specified link
                  }}
                >
                  <div className="">
                    {/* <i className={`bx ${item.icon}`} /> */}
                    <img src={item.icon} width={'18px'} height={'19px'}/>
                  </div>
                  <div className="menu-title text-md" style={{color:location.pathname == item.href && '#008cff'}}>{item.name}</div>
                </a>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <a href="javascript:;" className="has-arrow" aria-expanded={`${ShowDropDown2 ? 'true' : 'false'}`}
            onClick={() => setShowDropDown2(!ShowDropDown2)}
          >
            <div className="parent-icon">
              {/* <i className="bx bx-user-plus" /> */}
              <img src='/assets/icons/exam_management.png' width={'19px'} height={'20px'}/>
            </div>
            <div className="menu-title text-md">Exam Management</div>
          </a>
          <ul className={`${ShowDropDown2 ? 'd-block' : 'd-none'}`}>
            {ExamMenu.map((item, index) => (
              <li key={index}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default behavior
                    navigate(item.href); // Navigate to the specified link
                  }}
                >
                  <div className="">
                    {/* <i className={`bx ${item.icon}`} /> */}
                    <img src={item.icon} width={'18px'} height={'19px'}/>
                  </div>
                  <div className="menu-title text-md" style={{color:location.pathname == item.href && '#008cff'}}>{item.name}</div>
                </a>
              </li>
            ))}
          </ul>
        </li>
      </ul>
      {/*end navigation*/}
    </div >


  )
}

export default Sidebar
